import React from "react"
import { SKILLSImages } from "../gatsby-images/skillsImages"
import Fade from 'react-reveal/Fade'
import UseAnimations from "react-useanimations";
import arrowRightCircle from 'react-useanimations/lib/arrowRightCircle'
import arrowDown from 'react-useanimations/lib/arrowDown'
import { Link } from "gatsby"

const vision = () => {
  return (
    <div className="projects">
      <ProjectHeader />
      <Fade bottom delay={10}>
      <W1 />
      </Fade>
      <Fade bottom delay={20}>
      <W2/>
      </Fade>
      <Fade bottom delay={30}>
      <W3 />
      </Fade>
      <Fade bottom delay={40}>
      <W4 />
      </Fade>
      <Fade bottom delay={50}>
      <W5/>
      </Fade>
      <Fade bottom delay={60}>
      <W6 />
      </Fade>
      <Dribbble />
    </div>
  )
}


const W1 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
      <div className="work-sub">
      <h6>SITE WEB</h6>
      </div>
      <div className="work-title">
            <h5>Services digitaux</h5>
          </div>
         <div className='prix'>À partir de 280 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Pack Landing Page</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
               Une page de présentation. 
              </li>
              <li className="frstyle">
              Formulaire de contact.
              </li> 
              <li className="frstyle">
              Design personnalisé.
              </li> 
              <li className="frstyle">
              Administration pour export de données.
              </li>
              <li className="frstyle">
              Hébergement et nom de domaine offert *.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
       
        
         <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 390 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Pack Site Vitrine</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Design personnalisé.
              </li>
              <li className="frstyle">
              Nombre de pages (5).
              </li> 
              <li className="frstyle">
              Formulaire de contact.
              </li> 
              <li className="frstyle">
              Optimisé pour le référencement naturel.
              </li>
              <li className="frstyle">
              Back-office pour la gestion de contenu.
              </li>
              <li className="frstyle">
              Formation Back office.
              </li>
              <li className="frstyle">
              Hébergement et nom de domaine offert *.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>


          <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 1197 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Pack E-commerce</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Site marchand personnalisé.
              </li>
              <li className="frstyle">
              Catalogue de produits (20).
              </li> 
              <li className="frstyle">
              Back office pour la gestion de produits et de contenu. 
              </li> 
              <li className="frstyle">
              Moteur de recherche.
              </li>
              <li className="frstyle">
              Paiement en ligne.
              </li>
              <li className="frstyle">
              Formation pour gestion Back office.
              </li>
              <li className="frstyle">
              Hébergement et nom de domaine offert *.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>


          </div>
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={3} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}

const W2 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
      <div className="work-sub">
      <h6>APPLICATION MOBILE</h6>
      </div>
      <div className="work-title">
            <h5>Service de développement</h5>
          </div>
         <div className='prix'>À partir de 476 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Design d'application</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Conception des wireframe. 
              </li>
              <li className="frstyle">
              Optimisation du parcours utilisateur.
              </li> 
              <li className="frstyle">
              Création du prototype interactif.
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
       
        
         <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 889 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Application iOS/ Android</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Les applications natives sont plus sécurisées.
              </li>
              <li className="frstyle">
              Les applications natives ont les meilleures performances.
              </li> 
              <li className="frstyle">
              Les applications natives sont plus interactives et intuitives.
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>

          <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 689 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Application Hybride</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Les applications sont multi-plateforme.
              </li>
              <li className="frstyle">
              Les applications natives sont techniquement assez limitée.
              </li> 
              <li className="frstyle">
              Les applications natives permet de réduire de façon significative les coûts et les délais de développement.
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
          </div>
       
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={12} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}


const W3 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
      <div className="work-sub">
      <h6>E-RÉPUTATION</h6>
      </div>
      <div className="work-title">
            <h5>Services Marketing</h5>
          </div>
         <div className='prix'>À partir de 367 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Startup Pack</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Veille permanente.
              </li>
              <li className="frstyle">
              Remontées en temps réel des informations.
              </li> 
              <li className="frstyle">
              Conseil sur la nature des réponses.
              </li> 
              <li className="frstyle">
              Reporting mensuel des avis.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
       
        
         <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 849 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Premium Pack</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Veille permanente.
              </li>
              <li className="frstyle">
              Remontées en temps réel des informations.
              </li> 
              <li className="frstyle">
              Community management actif sur Facebook.
              </li> 
              <li className="frstyle">
              Optimisé pour le référencement naturel.
              </li>
              <li className="frstyle">
              Rédactions de publications.
              </li>
              <li className="frstyle">
              Reporting mensuel des avis.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>


          <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 989 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Ultimate Pack</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Veille permanente.
              </li>
              <li className="frstyle">
              Veille permanente.
              </li> 
              <li className="frstyle">
              Community management actif sur les réseaux sociaux.
              </li> 
              <li className="frstyle">
              Rédactions de publications.
              </li>
              <li className="frstyle">
              Publications des réponses sur blogs et sites.
              </li>
              <li className="frstyle">
              Reporting mensuel des actions et avis.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
          </div>
        <div className="workimage">
        <div className="up">
        <SKILLSImages id={2} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}


const W4 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
      <div className="work-sub">
      <h6>RÉFÉRENCEMENT NATUREL SEO</h6>
      </div>
      <div className="work-title">
            <h5>Service Positionnement Web</h5>
          </div>
         <div className='prix'>À partir de 150 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Startup Pack</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Optimisation simple du contenu.
              </li>
              <li className="frstyle">
              Optimisation simple du contenu.
              </li> 
              <li className="frstyle">
              Maillage interne.
              </li> 
              <li className="frstyle">
              Rapport mensuel de visite.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
       
        
         <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 299 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Premium Pack</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Optimisation de contenu.
              </li>
              <li className="frstyle">
              Maillage interne.
              </li> 
              <li className="frstyle">
              Optimisé pour le référencement naturel.
              </li> 
              <li className="frstyle">
              Rapport hebdomadaire.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>


          <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 429 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Ultimate Pack</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Optimisation de contenu avancée.
              </li>
              <li className="frstyle">
              Maillage interne.
              </li> 
              <li className="frstyle">
              Mise à jour de sitemap et soumission.
              </li> 
              <li className="frstyle">
              Rapport hebdomadaire avec analyse de résultats.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
          </div>
        <div className="workimage">
        <div className="up">
        <SKILLSImages id={11} />
        </div>
    <div className="background-b"></div>
    </div>
</div>
  
  )
}



const W5 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
      <div className="work-sub">
      <h6>WEBMARKETING</h6>
      </div>
      <div className="work-title">
            <h5>Service de marketing web</h5>
          </div>
         <div className='prix'>À partir de 119 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             SMO: Optimisation pour les médias sociaux</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Interfaçage des modules Sociaux sur votre site Internet.
              </li>
              <li className="frstyle">
              Saisie des informations importantes de votre entreprise.
              </li> 
              <li className="frstyle">
              Mise en avant de vos prestations sur vos comptes LinkedIn et Viadeo.
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
       
        
         <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 200 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             SEM: Marketing sur les moteurs de recherche</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Création de vos Fans pages sur les réseaux sociaux.
              </li>
              <li className="frstyle">
              Interfaçage des modules Sociaux sur votre site Internet.
              </li> 
              <li className="frstyle">
              Saisie des informations importantes de votre entreprise.
              </li>
              <li className="frstyle">
              Mise en avant de vos prestations sur vos comptes LinkedIn et Viadéo.
              </li> 
              <li className="frstyle">
              Animation et modération des commentaires blogs, forums, Twitter, Facebook.
              </li>
              <li className="frstyle">
              Recrutement Fan Facebook.
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>

          <div style={{margin: "2rem 0 0 0"}} className='prix'>Sur devis</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             SEA: Publicité sur les moteurs de recherche</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Création de vos Fans pages sur les réseaux sociaux.
              </li>
              <li className="frstyle">
              Interfaçage des modules Sociaux sur votre site Internet.
              </li> 
              <li className="frstyle">
              Saisie des informations importantes de votre entreprise.
              </li>
              <li className="frstyle">
              Mise en avant de vos prestations sur vos comptes LinkedIn et Viadéo.
              </li> 
              <li className="frstyle">
              Animation et modération des commentaires blogs, forums, Twitter, Facebook.
              </li>
              <li className="frstyle">
              Surveillance de vos pages et Modération web social.
              </li> 
              <li className="frstyle">
              Publipostage de contenu valorisant sur blog, forum et autres médias sociaux
              </li>
              <li className="frstyle">
              Interactions virales par l’organisation de jeu concours
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
          </div>
       
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={8} />
        </div>
    <div className="background-b"></div>
    </div>
</div>

  )
}

const W6 = () => {
  return (
 
    <div className="work-container">
      <div className="container-work">
      <div className="work-sub">
      <h6>DESIGN</h6>
      </div>
      <div className="work-title">
            <h5>Service de Création et Conception Graphique</h5>
          </div>
         <div className='prix'>À partir de 100 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Pack Nom + Logo</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Recherche de nom.
              </li>
              <li className="frstyle">
              Création de logo.
              </li> 
              <li className="frstyle">
              Déclinaison papeterie.
              </li> 
              <li className="frstyle">
              Sources fournies.
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
       
        
         <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 220 €</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Pack Charte Graphique</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Plaquette commerciale, institutionnelle, ..
              </li>
              <li className="frstyle">
              Interfaçage des modules Sociaux sur votre site Internet.
              </li> 
              <li className="frstyle">
              Dépliant, flyer, prospectus, carte de visite, ..
              </li>
              <li className="frstyle">
              Panneau, affiche, pancarte publicitaire, ..
              </li> 
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>

          <div style={{margin: "2rem 0 0 0"}} className='prix'>À partir de 190 €/ jour</div>
          <div className="work-description">
            <ui>
            <li style={{margin: "1rem 0 0.5rem 0"}}>
             <div className='linee'></div>
             Pack Bannières & Pubs</li>
             <div className='description'>
              <ui className='listfr'>
              <li className="frstyle">
              Bannières HTML 5 ou Flash
              </li>
              <li className="frstyle">
              Optimisation de poids pour les régies.
              </li> 
              <li className="frstyle">
              Saisie des informations importantes de votre entreprise.
              </li>
              </ui> 
             </div>
              <li>
              <div className="btn-hire">
               <a href="mailto:raphael@paaolms.com" className="Hello">
               Devis personnalisé 
              </a>
              <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.3} strokeColor="#F2F3F4"style={{}} />
               </div>
              </li>
            </ui>
          </div>
          </div>
       
       

        <div className="workimage">
        <div className="up">
        <SKILLSImages id={4} />
        </div>
    <div className="background-b"></div>
    </div>
</div>

  )
}


const ProjectHeader = () => {
  return (
    <Fade top delay={10}>
    <div className="project-header">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>Mon objectif?</h5>
          </div>
          <div className="project-description">
            <p>
            J'apporte à mes clients des solutions web efficaces et sur-mesure pour vous accompagner dans le développement de votre activité. Internet est un secteur très concurrentiel et il est nécessaire d’adopter une bonne stratégie de communication digitale pour faire de votre projet un succès.
            j'ai à cœur de vous proposer la solution optimale pour chacun de vos projets de communication.
            </p>
          </div>

        </div>
      </div>
    </div>
    </Fade>
  )
}

 
   
const Dribbble = () => {
  return (
    <div className="dribble-showcase">
      <div className="container">
        <div className="dribble-showcase-inner">
          <h4>A propos de moi?</h4>
          <span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://paaolms.com/à-propos-de-moi/"
              className="drib"
            >
            Me connaître<UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.2} strokeColor="#F2F3F4"style={{}} />
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default vision
