import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const SKILLSImages = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      skillsImages1: file(relativePath: { eq: "influenceur.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillsImages2: file(relativePath: { eq: "Started.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillsImages3: file(relativePath: { eq: "IOT.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillsImages4: file(relativePath: { eq: "Motion.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillsImages5: file(relativePath: { eq: "Startup.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skillsImages6: file(relativePath: { eq: "Legaltech.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      skillsImages7: file(relativePath: { eq: "Marketing.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      skillsImages8: file(relativePath: { eq: "Online.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      skillsImages9: file(relativePath: { eq: "Maintenance.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      

      skillsImages10: file(relativePath: { eq: "Regtech.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      skillsImages11: file(relativePath: { eq: "Femtech.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }


      skillsImages12: file(relativePath: { eq: "franchise.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      skillsImages13: file(relativePath: { eq: "Cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }


      skillsImages14: file(relativePath: { eq: "Wealthtech.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }


    }
  `)

  return (
    <>
      {id === 1 ? (
        <Img fluid={data.skillsImages1.childImageSharp.fluid} />
      ) : id === 2 ? (
        <Img fluid={data.skillsImages2.childImageSharp.fluid} />
      ) : id === 3 ? (
        <Img fluid={data.skillsImages3.childImageSharp.fluid} />
      ) : id === 4 ? (
        <Img fluid={data.skillsImages4.childImageSharp.fluid} />
      
       ) : id === 5 ? (
          <Img fluid={data.skillsImages5.childImageSharp.fluid} />
      
          ) : id === 6 ? (
            <Img fluid={data.skillsImages6.childImageSharp.fluid} />
            ) : id === 7 ? (
              <Img fluid={data.skillsImages7.childImageSharp.fluid} />
              ) : id === 8 ? (
                <Img fluid={data.skillsImages8.childImageSharp.fluid} />
                ) : id === 9 ? (
                  <Img fluid={data.skillsImages9.childImageSharp.fluid} />
                  ) : id === 10 ? (
                    <Img fluid={data.skillsImages10.childImageSharp.fluid} />
                    ) : id === 11 ? (
                      <Img fluid={data.skillsImages11.childImageSharp.fluid} />
                      ) : id === 12 ? (
          <Img fluid={data.skillsImages12.childImageSharp.fluid} />                                                         
       ) : id === 13 ? (
        <Img fluid={data.skillsImages13.childImageSharp.fluid} />
      ) : (
        <Img fluid={data.skillsImages14.childImageSharp.fluid} />
      )}
       
    </>
  )
}